let animationTargets = document.getElementsByClassName( "js-target-section" );
animationTargets = [].slice.call(animationTargets);
const limit = 40;
let pageScroll;
let windowHeight = window.innerHeight;

window.addEventListener('scroll', (e) => {
  pageScroll = window.pageYOffset;
  animationTargets.map((target) => {
    let targetPosition = target.getBoundingClientRect();
    if (pageScroll > targetPosition.bottom + limit) {
      target.classList.add('animated-on');
    }
  });
});
