$(function(){
  $('.js-facility-slider').slick({
    dots: false,
    arrows: true,
    variableWidth: true,
    infinite: true,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 3000,
  });
  $('.js-facility-header-slideshow').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    fade: true,
    autoplay: true,
    autoplaySpeed: 3000,
  });
  $('.js-facility-detail-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.js-facility-detail-slider-nav'
  });
  $('.js-facility-detail-slider-nav').slick({
    slidesToShow: 8,
    slidesToScroll: 1,
    variableWidth: true,
    asNavFor: '.js-facility-detail-slider',
    focusOnSelect: true
  });
});